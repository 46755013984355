<template>
  <div class="About">
    <div class="about-us-section" style="margin-bottom: 50px;">
      <div class="container">
	<div class="row">
	  <div class="col-lg-6 col-12">
	    <img src="images/home/4.jpg" alt="">
	  </div> <!-- /.col- -->
	  <div class="col-lg-6 col-12">
	    <div class="text-wrapper description">
	      <h3><b>Ten Factor Growth</b></h3>
	      <p>Since 2017, Ten Factor Growth has helped
		technology companies accelerate their
		growth and potential for success. I provide consulting on
		technology strategy, architecture, product management,
		and operational growth.</p>


	      <p>
		Each company stage requires different levels of
		process, decision making, technological sophistication 
		and partnerships.  Processes, staff, and technology
		must adapt to fit your company's stage and capabilities. 
		Startups often struggle to find
		top talent which can result in 
		execution errors  slowing growth. As a company grows,
		process and tech must mature to support distributed
		communications and decision making.  </p>

	      <p>
		I've managed companies through the early seed  stage
		up to
		established growth stages. I've managed technology
		with hundreds of developers and dozens of 
		products.  I've applied these approaches as an
		advisor, part-time consultant, and temporary CTO in 
		consumer products, healthtech, edtech, robotics, and
		general enterprise.
	      </p>
	      



	      <p> <a href="mailto:brian@tenfactorgrowth.com"> Contact
	      me
	      </a>
	      if you are struggling with technology and
	  growth. </p>
	    </div> <!-- /.text-wrapper -->
	  </div> <!-- /.col- -->
	</div> <!-- /.row -->
	
      </div> <!-- /.container -->
    </div> <!-- /.about-us-section -->


    <div class="portfolio-details" style="background:#f6f6f6 !important;">
      <div class="container">

	<div class="details-text">
	  <div class="row">
	    <div class="col-lg-6 col-md-6 col-12 text-left-side description">
	      <h2>Brian Eberman, Ph.D.</h2>
	      
	      <p style="margin-top:30px;">With over 30 years
	      of experience bringing new technologies and companies
	      to market,  I provide product
	      strategy, software technology development, and
	      operational execution consulting and advising to early
	      and growth stage companies.</p>

	      <p> With a technical background in AI, statistics,
		robotics, and speech recognition, I often work on
		problems involving distributed  computation or data driven decisions.
	      </p>
	    </div>
	    <div class="col-lg-6 col-md-6 col-sm-8 col-12">
	      <img class="headshot img-fluid"
		src="images/headshot.jpg" alt="">
	    </div> <!-- /.portfolio-info-list -->
	    <div style="clear:both;"></div>
	  </div>

	  <div class="row">
	    <div class="col-lg-12 col-md-12 col-sm-12 col-12 description">
	      <ul class="desc-list">
		  <li>
		    At Nuance, I led the transformation from a proprietary API
	architecture to an open web based architecture that now
	represents the primary way call-center speech applications are
	delivered. </li>

	<li> The mobile first, services based,
		integrated online tutoring architecture that I drove
			   at EnglishCenral, an edtech company,
		created ongoing significant growth for the company. </li>

			<li> At Jibo, the consumer robotics company,
		I quickly delivered a new software approach that restructured the
		       software architecture to meet the business needs of the
		company. This
		architecture moved the company from an application
		install approach to a distributed model
		combining  local computation with application
		control in the cloud. </li>

		<li> At CourseAdvisor,
		I help design statistical decision processes for
		   optimizing landing pages to advertisements,
		selecting advertising, and improving lead conversion
					   rates.
		</li>

		  <li> At Exos, I designed a distributed computation
		    system to provide haptic feedback with a force controlled joystick.
		    Microsoft acquired Exos and applied this design to
		    create the Sidewinder Joystick - selling over 100M
		    units. </li>

		  </ul>
		  
			      
			      
	      	      
              <p>I bring decades of experience in distributed
              systems, speech technology, robotics,
		and data analytics. This includes a Ph.D. in robotics
		    and AI, over 14 years
              running early stage companies, and 10 years in
              speech recognition including as Vice President of
              Product Management at Nuance
              Communication. </p>
	      
	    </div>
	  </div> <!-- /.row -->
	</div> <!-- /.details-text -->
      </div> <!-- /.container -->
    </div>



  </div>
  </template>

<script>
  export default {
    name: 'About',
    title: 'About - Ten Factor Growth'
}
  </script>

<style>
.description  p  {
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
  }
  .headshot{
      width: 40%;
      height: auto;
}

.desc-list  {
    padding-left: 15pt;
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
}

</style>
